import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import KeynoteSection from "../components/dynamic-sections/KeynoteSection"

/** svg */
import VideoIcon from "../../assets/video-icon.svg"
import StarReviewIcon from "../../assets/five-star-rating.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const AwardsPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/awards/",
          name: "Awards | Norhart",
          image: `${config.siteMetadata.siteUrl}/awards/norhart-awards-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Awards | Norhart"
      description="Norhart is an award-winning construction and Property management company that is a Top Place To Work in Minnesota. Our people, culture, and values are our most important differentiators!"
      keywords="norhart, award, minnesota, construction, property, management, values, growth, innovative, lean, amazing, exciting, forest lake, blaine, oakdale, minneapolis"
      imageTwitter={`${config.siteMetadata.siteUrl}/awards/norhart-awards-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/awards/norhart-awards-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Awards"
        subtitle="Yeass! Norhart Is A Top Place To Work"
        imageTitle="Norhart Awards"
        image="/awards/norhart-award-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Top Place in Minnesota To Work"
        tagLine="Our People Love Working at Norhart"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Awards"
        title="Award Winning Teams"
        subTitle="Yup! Based entirely on private and anonymous employee feedback captured with the Top Workplace Survey, Norhart proves it puts its people first. #WooHoo"
        image="/awards/norhart-constuction-awards.png"
        imageAlt="Norhart Support Team"
        buttonName="Learn More"
        buttonUrl="https://topworkplaces.com/company/norhart/"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <HeroBottomSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#EFEFEF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"I know that everyone along side of me is working as hard as I am every day, without question. We are here for each other." - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Formula"
        title="People + Culture = Awards"
        subTitle="Hell Yes! Norhart's people, culture, and values are our most important differentiator! Winning the Top Workplaces award proves that fact. #highfive #kickass #work@norhart"
        image="/awards/norhart-team404-awards.png"
        imageAlt="Norhart Culture and Awards"
        textColor="#FFFFFF"
        asoEffect="fade-in"
      />

      <HeroBottomSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#EFEFEF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"If I won the lottery today. I'd still come to work tomorrow because the work is so fufilling!" - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart People"
        title="Great Hires Make A Company"
        subTitle="Recruiting the right talent sets us apart from the competition in our industry! This is the main reason Norhart stands out!"
        image="/awards/norhart-recruiting-awards.png"
        imageAlt="Great Hires Make A Company"
        textColor="#FFFFFF"
        asoEffect="fade-in"
        buttonName="Connect With Recruiting"
        buttonUrl="/recruiter-team/"
      />

      <HeroBottomSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#EFEFEF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"I get to work with amazing people everyday, and I am pushed to learn and improve myself in everytthing I do!" - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default AwardsPage
